import { useEffect } from "react";
declare global {
  interface Window {
    adsbygoogle?: Array<{}>;
  }
}
export default function GoogleAds(props: any) {
  useEffect(() => {
    try {
      const timeoutId = setTimeout(() => {
        if (window) {
          window.adsbygoogle = window.adsbygoogle || [];
          window.adsbygoogle.push({});
        }
      }, 50);
      return () => clearTimeout(timeoutId);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <ins
      className={`adsbygoogle`}
      style={{ display: "block" }}
      data-ad-client={"ca-pub-4887457600239501"}
      data-ad-slot={"1470076252"}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
}
