import moment from "moment";

const CropImage = async (ratio: any, imageRef: any, urls: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (imageRef) {
        imageRef.crossOrigin = "anonymous";
        imageRef.onload = async function (owner: any) {
          const canvas = document.createElement("canvas");
          const scaleX = imageRef.naturalWidth / imageRef.width;
          const scaleY = imageRef.naturalHeight / imageRef.height;
          const scaleFactor = 10;
          canvas.width = ratio.width! * scaleFactor;
          canvas.height = ratio.height! * scaleFactor;
          const ctx = canvas.getContext("2d")!;
          ctx.drawImage(
            imageRef,
            ratio.x! * scaleX,
            ratio.y! * scaleY,
            ratio.width! * scaleX,
            ratio.height! * scaleY,
            0,
            0,
            ratio.width! * scaleFactor,
            ratio.height! * scaleFactor
          );
          const dataUrl = canvas.toDataURL("image/png", 1.0);
          const blob = dataURLtoBlob(dataUrl);
          let name = moment(new Date()).unix();
          let url = new File([blob], name + "-crop.png", { type: "image/png" });
          resolve(url);
          imageRef.crossOrigin = "";
        };
        imageRef.src = urls;
        imageRef.onerror = function () {
          imageRef.crossOrigin = "";
          console.log("image load faild");
        };
      } else {
        resolve("error");
      }
    } catch (err) {
      console.log("err", err);
      resolve("error");
    }
  });
};

const dataURLtoBlob = (dataURL: string) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export default CropImage;
