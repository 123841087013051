import React from "react";
import "./style.scss";
import { Container } from "react-bootstrap";
import { IoShareSocial } from "react-icons/io5";
import { BiLogoFacebook } from "react-icons/bi";
import { RiTwitterXFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import Logo from "../../asset/images/logo_light.png";
import Timer from "./time";
import { useNavigate } from "react-router-dom";
const Header = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="Header">
      <Container>
        <div className="Header-box">
          <div className="Header-box1">
            <Timer onChange={(val: any) => props.onChange(val)} />
          </div>
          <div className="Header-box4">
            <Timer onChange={(val: any) => props.onChange(val)} />
          </div>
          <div className="Header-box2" onClick={() => navigate("/")}>
            <img src={Logo} className="Header-logo" alt="" />
          </div>
          <div className="Header-box3">
            <div className="Header-icon">
              <a
                href="https://www.youtube.com/@Suprabhaatham2023"
                target="_blank"
              >
                <AiOutlineYoutube size={20} color="#000" />
              </a>
            </div>
            <div className="Header-icon">
              <a
                href="https://twitter.com/suprabhaatham?lang=en"
                target="_blank"
              >
                <RiTwitterXFill size={18} color="#000" />
              </a>
            </div>
            <div className="Header-icon">
              <a
                href="https://www.instagram.com/suprabhaathamonline/?hl=en"
                target="_blank"
              >
                <FaInstagram size={20} color="#000" />
              </a>
            </div>
            <div className="Header-icon">
              <a href="https://www.facebook.com/Suprabhaatham/" target="_blank">
                <BiLogoFacebook size={20} color="#000" />
              </a>
            </div>
          </div>
          <div className="Header-box4"></div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
