import "./adds.scss";
import { useEffect } from "react";
declare global {
  interface Window {
    adsbygoogle?: Array<{}>;
  }
}
export default function BoxAdd(props: any) {
  useEffect(() => {
    try {
      const timeoutId3 = setTimeout(() => {
        if (window) {
          window.adsbygoogle = window.adsbygoogle || [];
          window.adsbygoogle.push({});
        }
      }, 40);
      return () => clearTimeout(timeoutId3);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="BoxAdd">
    <ins
      className="adsbygoogle"
      style={{ display: "inline-block", width: "728px", height: "95px" }}
      data-ad-client="ca-pub-4887457600239501"
      data-ad-slot="1446936623"
    ></ins>
    </div>
  );
}
