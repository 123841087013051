"use client";
import { useState } from "react";
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
const Timer = (props: any) => {
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date());
  const format = "dddd DD, MMMM YYYY";
  const onDateChange = (date: any, string: any) => {
    setCurrentTime(date);
    let url: any = `/?date=${moment(string).format("YYYY-MM-DD")}`;
    navigate(url);
  };

  return (
    <DatePicker
      className="Header_timerBox_DatePicker"
      value={currentTime ? dayjs(currentTime) : undefined}
      defaultValue={dayjs(new Date())}
      format={format}
      bordered={false}
      onChange={onDateChange}
      allowClear={false}
    />
  );
};

export default Timer;
