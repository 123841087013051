import { API } from "../Config/API";

const getURL = (url: any) => {
  try {
    if (url && url?.includes("e-files.suprabhaatham.com")) {
      return url;
    }
    let ite_ = url?.split("/");
    let URL = `${ite_[3]}/${ite_[4]}`;
    let imageURL = API.CDN_URL + URL;
    return imageURL;
  } catch (err) {
    console.log("err", err);
    return url;
  }
};

export { getURL };
