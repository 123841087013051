import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import moment from "moment";
import { message, FloatButton } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { PiSelection } from "react-icons/pi";

import Header from "../../component/header";
import Footer from "../../component/footer/footer";
import SubHeader from "./subHeader";
import PageHeader from "./components/pageHeader";
import BoxAdd from "../../component/adds/boxAdd";
import GoogleAds from "../../component/adds/googleAdds";

import { API } from "../../Config/API";
import { POST } from "../../utils/apiCalls";

import CropBox from "./components/cropBox";
import SelectionBox from "./components/selectionBox";

import { getURL } from "../../utils/getCdnURL";
import CropImage from "./helpers/cropImage";
import SharePopup from "./components/sharePopup";
import ZoomPopup from "./components/zoomPopup";

function DetailScreen() {
  const params = useParams();
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [activeIndex, setActiveIndex] = useState(0);
  const [activePage, setActivePage] = useState<any>(null);
  const [activePreview, setactivePreview] = useState(false);
  const [activeZoom, setActiveZoom] = useState<any>(false);
  const [cropActive, setCropActive] = useState(false);

  const [cropComplete, setCropComplete] = useState(false);
  const [cropValue, setCropValue] = useState({});

  const [activeShare, setActiveShare] = useState(false);
  const [cropFile, setCropFile] = useState(null);

  // devtool
  window.addEventListener("keydown", function (event) {
    if (event.ctrlKey && event.shiftKey && event.key === "I") {
      event.preventDefault();
      message.error("Developer Tools are disabled.");
    }
  });

  // right click
  document.addEventListener("contextmenu", (event) => event.preventDefault());

  useEffect(() => {
    const edition = params?.edition;
    const date = params?.date;
    const page = params?.page;
    setIsLoading(true);
    loadData(edition, date, page);
    window.scrollTo(0, 0);
  }, [params]);

  const loadData = async (edition: any, date: any, page: any) => {
    try {
      setIsLoading(true);
      let url = API.EPAPER;
      let obj = {
        edition: edition,
        limit: 8,
        createdAt: moment(date).format("YYYY-MM-DD"),
      };
      const response: any = await POST(url, obj);
      if (response?.length) {
        setData(response[0]);
        ChangePage(response[0]?.epapercollections[0]);
      } else {
        setData([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const clickArrow = (type: any) => {
    try {
      setIsLoading(true);
      if (type === "add") {
        var totalPages = Number(data?.epapercollections?.length) - 1;
        var num = activeIndex + 1;
        if (num >= totalPages) {
          setActiveIndex(totalPages);
          message.info("Maximum page limit reached.");
        } else {
          setActiveIndex(num);
        }
      } else {
        var num = activeIndex - 1;
        if (num < 0) {
          setActiveIndex(0);
          message.info("Minimum page limit reached.");
        } else {
          setActiveIndex(num);
        }
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 5);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.epapercollections?.length) {
      setIsLoading(true);
      let item = data?.epapercollections[activeIndex];
      ChangePage(item);
    }
  }, [activeIndex]);

  const ChangePage = async (file: any) => {
    try {
      setActivePage(null);
      if (file?.imageUrl) {
        setActivePage(file);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  };

  const onFinishCrop = async (value: any) => {
    try {
      setIsLoading2(true);
      setActiveShare(true);
      let active = activePage;
      let cropedFile: any = await CropImage(
        value?.area,
        value?.ref,
        activePage?.imageUrl
      );
      setCropFile(cropedFile);
      setIsLoading2(false);
      setActivePage({});
      setTimeout(() => {
        setActivePage(active);
      }, 5);
    } catch (err) {
      setActiveShare(false);
      setIsLoading2(false);
      console.log("err", err);
    }
  };

  return (
    <div>
      <Header />
      <SubHeader edition={params?.edition} date={params?.date} />
      <div className="detailScreen">
        <div className="detailScreen-arrow-leftBox">
          <div
            className="detailScreen-arrow-left"
            onClick={() => clickArrow("remove")}
          >
            <IoIosArrowBack size={40} />
          </div>
        </div>
        <Container fluid={false}>
          <Row>
            <Col sm={2} xs={12}>
              <GoogleAds />
            </Col>
            <Col sm={8} xs={12}>
              <BoxAdd />
              {isLoading ? (
                <div className="detailScreen-loading">Loading . . . </div>
              ) : (
                <div>
                  <PageHeader
                    totalPage={data?.epapercollections}
                    activeIndex={activeIndex}
                    acitvePreview={activePreview}
                    cropActive={cropActive}
                    activeZoom={activeZoom}
                    changePreview={(value: any) => setactivePreview(value)}
                    changeIndex={(value: any) => setActiveIndex(value)}
                    clickArrow={(value: any) => clickArrow(value)}
                    setCropActive={() => setCropActive(!cropActive)}
                    setActiveZoom={() => setActiveZoom(!activeZoom)}
                  />
                  <div className="detailScreen-box">
                    {cropActive ? (
                      <CropBox
                        page={activePage}
                        onChange={(value: any) => {
                          setCropValue(value);
                          if (value) {
                            setCropComplete(true);
                          } else {
                            setCropComplete(false);
                            setCropActive(!cropActive);
                          }
                        }}
                      />
                    ) : (
                      <SelectionBox
                        page={activePage}
                        onChange={(value: any) => {
                          setCropValue(value);
                          if (value) onFinishCrop(value);
                        }}
                      />
                    )}
                  </div>
                  <div className="detailScreen-box3">
                    Page - {activeIndex + 1}
                  </div>
                  <GoogleAds />
                </div>
              )}
            </Col>
            <Col sm={2} xs={12}>
              <GoogleAds />
            </Col>
          </Row>
        </Container>
        <div className="detailScreen-arrow-rightBox">
          <div
            className="detailScreen-arrow-left"
            onClick={() => clickArrow("add")}
          >
            <IoIosArrowForward size={40} />
          </div>
        </div>
      </div>
      {cropComplete ? (
        <FloatButton
          style={{ width: 80, height: 60, marginBottom: 20 }}
          icon={
            <div style={{ marginRight: 5 }}>
              <PiSelection size={25} />
            </div>
          }
          description={"Create"}
          shape="square"
          type="primary"
          onClick={() => onFinishCrop(cropValue)}
        />
      ) : null}

      {activeZoom ? (
        <ZoomPopup
          show={activeZoom}
          onClose={() => setActiveZoom(false)}
          src={getURL(activePage?.imageUrl)}
        />
      ) : null}

      {activeShare ? (
        <SharePopup
          loading={isLoading2}
          file={cropFile}
          edition={params?.edition}
          page={activeIndex}
          date={params?.date}
          visible={activeShare}
          onClose={() => setActiveShare(false)}
        />
      ) : null}
      <Footer />
    </div>
  );
}

export default DetailScreen;
