import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GoHome } from "react-icons/go";
import Edition from "../../Config/edition.json";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const SubHeader = (props: any) => {
  const navigate = useNavigate();
  const format = "YYYY-MM-DD";

  const oneditionChange = (edit: any) => {
    let url: any = `/?date=&edition=${edit}`;
    navigate(url);
  };

  const onClickHome = (edit: any) => {
    let url: any = `/?date=${moment(new Date()).format(format)}&edition=`;
    navigate(url);
  };

  const onDateChange = (date: any, string: any) => {
    let url: any = `?date=${moment(string).format(format)}&edition=`;
    navigate(url);
  };

  return (
    <div className="Edition-box1">
      <div className="Edition">
        <Container fluid={false}>
          <div className="Edition-box">
            <div
              className={
                props.edition === "" || props.edition === null
                  ? "Edition-item active"
                  : "Edition-item"
              }
              onClick={() => onClickHome("")}
            >
              <div className="Edition-txt1">
                <GoHome size={20} />
              </div>
            </div>
            <div className="Edition-item2">
              <DatePicker
                value={dayjs(props?.date, format)}
                defaultValue={dayjs(props?.date, format)}
                bordered={false}
                allowClear={false}
                onChange={(value: any, string: any) =>
                  onDateChange(value, string)
                }
              />
            </div>
            {Edition?.map((item: any) => {
              return (
                <div
                  key={item.value}
                  className={
                    props.edition === item.value
                      ? "Edition-item active"
                      : "Edition-item"
                  }
                  onClick={() => oneditionChange(item.value)}
                >
                  <div className="Edition-txt1">{item.name}</div>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SubHeader;
