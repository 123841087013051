import { useRef } from "react";
import { getURL } from "../../../utils/getCdnURL";
import { Image } from "react-bootstrap";

const SelectionBox = (props: any) => {
  const cropperRef: any = useRef<HTMLImageElement>(null);

  const cropComplete = async (value: any) => {
    const parentElementWidth = cropperRef.current.clientWidth;
    const parentElementHeight = cropperRef.current.clientHeight;

    const croppedArea = {
      x: value?.startX * parentElementWidth,
      y: value?.startY * parentElementHeight,
      width: value?.width * parentElementWidth,
      height: value?.height * parentElementHeight,
      unit: "px",
    };
    if (croppedArea?.width && croppedArea?.height) {
      let obj = {
        area: croppedArea,
        ref: cropperRef.current,
      };
      console.log("cropComplete 1");
      props?.onChange(obj);
    } else {
      console.log("cropComplete 2");
      props?.onChange(null);
    }
  };

  return (
    <div className="detailScreen-CropPdf">
      <div className="detailScreen-imageContainer">
        {props?.page?.imageUrl ? (
          <Image
            ref={cropperRef}
            src={getURL(props?.page?.imageUrl)}
            className="detailScreen-CropPdfImage"
          />
        ) : null}
        {props?.page?.coords?.map((pos: any, index: number) => (
          <div
            className="detailScreen-croodarea"
            key={index}
            style={{
              left: `${pos.startX * 100}%`,
              top: `${pos.startY * 100}%`,
              width: `${pos.width * 100}%`,
              height: `${pos.height * 100}%`,
            }}
            onClick={() => cropComplete(pos)}
          />
        ))}
      </div>
    </div>
  );
};
export default SelectionBox;
