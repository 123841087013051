import AWS from "aws-sdk";
import { API } from "../Config/API";
window.Buffer = window.Buffer || require("buffer").Buffer;
const s3 = new AWS.S3({
  accessKeyId: "AKIAZR4MRHMHJZWAYS66",
  secretAccessKey: "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",
  region: "ap-south-1",
});
const bucket = "suprabhaatham-bucket/epaper-cuts";

const GET = async (url: any, params: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const POST = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

 const UPLOADTOS3 = (file: any, filename: any) => {
  let fileLabel = filename?.replace(/\s+/g, "_");
  var buf = Buffer.from(file?.replace(/^data:image\/\w+;base64,/, ""),'base64')
  const params = {
    Bucket: bucket,
    Key: fileLabel,
    Body: buf,
    ACL: "public-read",
    ContentEncoding: 'base64',
    ContentType: 'image/jpeg'
  };
  return s3.upload(params).promise();
};

export { GET, POST ,UPLOADTOS3};
