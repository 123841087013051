import "./App.css";
import { Route, Routes } from "react-router-dom";

import Homescreen from "./screens/homescreen";
import DetailScreen from "./screens/detailScreen";
import PreviewScreen from "./screens/previewScreen";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
    theme={{
      token: {
        fontFamily:"NotoSansMalayalam-SemiBold",
        colorPrimary: 'rgb(5, 118, 183)',
        borderRadius: 2,
      },
    }}
  >
    <Routes>
      <Route index element={<Homescreen />} />
      <Route path="/" element={<Homescreen />} />
      <Route
        path="/details/:edition/:date/:page"
        element={<DetailScreen />}
      />
        <Route path="/preview" element={<PreviewScreen />} />
    </Routes>
    </ConfigProvider>
  );
}

export default App;
