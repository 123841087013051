import React from "react";
import "../styles.scss";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { IoClose } from "react-icons/io5";
import { BsZoomIn } from "react-icons/bs";
import { BsZoomOut } from "react-icons/bs";
import { GrPowerReset } from "react-icons/gr";
import { Container } from "react-bootstrap";

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  return (
    <div className="detailScreen-zoomControl">
      <div onClick={() => zoomOut()}>
        <BsZoomOut size={20} />
      </div>
      <div onClick={() => resetTransform()}>
        <GrPowerReset size={20} />
      </div>
      <div onClick={() => zoomIn()}>
        <BsZoomIn size={20} />
      </div>
    </div>
  );
};

const ZoomPopup = ({ show, onClose, src }: any) => {
  if (!show) {
    return null;
  }

  return (
    <div className="detailScreen-ZoomPopup">
      <TransformWrapper initialScale={1} minScale={1}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div style={{ height: "100vh", width: "100vw" }}>
            <div onClick={onClose} className="detailScreen-zoomclose">
              <div className="detailScreen-zoomcloseIcon">
                <IoClose size={30} />
              </div>
            </div>
            <TransformComponent>
              <img src={src} className="detailScreen-zoomimage" />
            </TransformComponent>
            <div className="detailScreen-ZoomHeader">
              <Container>
                <div className="detailScreen-ZoomHeader2">
                  <div></div>
                  <Controls />
                  <div></div>
                </div>
              </Container>
            </div>
          </div>
        )}
      </TransformWrapper>
    </div>
  );
};

export default ZoomPopup;
