import { useEffect, useRef, useState } from "react";
import { getURL } from "../../../utils/getCdnURL";
import ReactCrop, { Crop } from "react-image-crop";
const CropBox = (props: any) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>({
    x: 20,
    y: 20,
    width: 200,
    height: 200,
    unit: "px",
  });

  useEffect(() => {
    setCrop(
      crop?.x > 0 ? crop : { x: 20, y: 20, width: 200, height: 200, unit: "px" }
    );
  }, []);

  const cropComplete = async (value: any) => {
    if (value?.width && value?.height) {
      let obj = {
        area: value,
        ref: cropperRef.current,
      };
      props?.onChange(obj);
    } else {
      props?.onChange(null);
    }
  };

  return (
    <div className="detailScreen-CropPdf">
      <ReactCrop
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={cropComplete}
      >
        {props?.page?.imageUrl ? (
          <img
            ref={cropperRef}
            src={getURL(props?.page?.imageUrl)}
            className="detailScreen-CropPdfImage"
          />
        ) : null}
      </ReactCrop>
    </div>
  );
};
export default CropBox;
