import "./adds.scss";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoIosCloseCircleOutline } from "react-icons/io";
declare global {
  interface Window {
    adsbygoogle?: Array<{}>;
  }
}
export default function StickyAdds(props: any) {
  const [show, setShow] = useState(true);
  useEffect(() => {
    try {
      const timeoutId2 = setTimeout(() => {
        if (window) {
          window.adsbygoogle = window.adsbygoogle || [];
          window.adsbygoogle.push({});
        }
      }, 40);
      return () => clearTimeout(timeoutId2);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return show ? (
    <div className="adds-sticky">
      <div style={{ margin: 16 }} onClick={() => setShow(false)}>
        <IoIosCloseCircleOutline color="red" size={30} />
      </div>
      <Container style={{ height: 100, marginTop: -59 }}>
        <ins
          className="adsbygoogle"
          style={{ display: "inline-block", width: "728px", height: "95px" }}
          data-ad-client="ca-pub-4887457600239501"
          data-ad-slot="1446936623"
        ></ins>
      </Container>
    </div>
  ) : null;
}
