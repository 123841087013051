import React from "react";

import "./footer.css";
function Footer() {
  return (
    <div className="Footer">
      Copyrights © 2024. All Right Reserved.
    </div>
  );
}

export default Footer;
