import {
  IoArrowBackOutline,
  IoArrowForward,
  IoDocumentText,
  IoDocumentTextOutline,
} from "react-icons/io5";
import { MdZoomIn, MdZoomOut } from "react-icons/md";
import { Select } from "antd";
import { IoMdCut } from "react-icons/io";
import { getURL } from "../../../utils/getCdnURL";

const PageHeader = (props: any) => {
  return (
    <div className="detailScreen-box1">
      <div className="detailScreen-header">
        <div className="detailScreen-headerBox">
          <div className="detailScreen-headerBox2">
            <div onClick={() => props?.clickArrow("remove")}>
              <IoArrowBackOutline size={25} />
            </div>
            <div className="detailScreen-box2">
              Page -
              <Select
                placeholder={"Pages"}
                bordered={false}
                style={{ width: 80 }}
                defaultValue={props?.activeIndex}
                value={props?.activeIndex}
                onChange={(index) => props?.changeIndex(index)}
              >
                {props?.totalPage?.map((items: any, index: number) => {
                  return (
                    <Select.Option key={index} value={index}>
                      {index + 1}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div onClick={() => props?.clickArrow("add")}>
              <IoArrowForward size={25} />
            </div>
          </div>
          <div
            className="detailScreen-btin2"
            style={{
              color: props?.activeZoom ? "rgb(5, 118, 183)" : "#000",
            }}
            onClick={() => props?.setActiveZoom()}
          >
            {props?.activeZoom ? (
              <MdZoomOut size={25} />
            ) : (
              <MdZoomIn size={25} />
            )}
            <div className="detailScreen-txt2">Zoom</div>
          </div>
          <div
            className="detailScreen-btin2"
            style={{ color: props?.cropActive ? "rgb(5, 118, 183)" : "#000" }}
            onClick={() => props?.setCropActive(!props?.cropActive)}
          >
            <IoMdCut size={25} />
            <div className="detailScreen-txt2">Crop</div>
          </div>

          <div
            className="detailScreen-btin2"
            style={{
              color: props?.acitvePreview ? "rgb(5, 118, 183)" : "#000",
            }}
            onClick={() => props?.changePreview(!props?.acitvePreview)}
          >
            {props?.acitvePreview ? (
              <IoDocumentText size={25} />
            ) : (
              <IoDocumentTextOutline size={25} />
            )}
            <div className="detailScreen-txt2">
              {props?.acitvePreview ? "Hide" : "Show"} Pages
            </div>
          </div>
        </div>
      </div>
      {props?.acitvePreview ? (
        <div className="detailScreen-PreviewBox">
          {props?.totalPage.length
            ? props?.totalPage.map((item: any, index: number) => {
                let imageURL = getURL(item?.imageUrl);
                return (
                  <div
                    key={index}
                    className="PreviewBox-item"
                    onClick={() => props?.changeIndex(index)}
                  >
                    <img src={imageURL} style={{ width: "100%" }} />
                    <div
                      className={`PreviewBox-selected ${
                        props?.activeIndex === index ? "active" : ""
                      }`}
                    >
                      {index + 1}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default PageHeader;
