import React from 'react'
import { useSearchParams } from 'react-router-dom';

function PreviewScreen() {
  const [searchParams] = useSearchParams();
  const url = searchParams.get("url");
  
  return (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}>
        {url && <img src={url} style={{objectFit:"contain",height:"100vh"}}/>}
    </div>
  )
}

export default PreviewScreen